import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        <footer className='border-top py-3 mt-5'>
        <Container>
            <Row className="justify-content-between" >
                <Col md={4}>
                    <p className='text-black-50'><small>Powered by <Link className='text-black-50' target='_blank' to="https://www.sagarinfotech.com/">Sagar Informatics Pvt. Ltd.</Link></small></p>
                </Col>
                <Col md={2}>
                    <div className="d-flex gap-2 justify-content-md-end">
                        <Link className='text-dark small text-nowrap' to="https://simfly.co.in/contactus" target='_blank'>Contact us</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </footer>
    </>
  )
}

export default Footer